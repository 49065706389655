<script>
import {currencyConfig} from '@utils/money'
import helpers from '@mixins/helpers'
import {mapGetters} from "vuex";

import {isNumber} from 'lodash'

export default {
  mixins: [helpers],
  name: 'player-class-amount',
  props: {
    amount: {
      default: 0
    },
    planDiscounts: {
      type: Array,
      default: () => []
    },
    active: {
      type: Boolean,
      default: true
    },
    showNoCost: {
      type: Boolean,
      default: true
    },
    noCost: {
      type: Boolean,
      default: false
    },
    index: {
      type: Number,
      default: 0
    },
    plans: {
      type: Array
    }
  },
  data() {
    return {
      localAmount: 0,
      localNoCost: false,
      localPlans: [],
      discountType: 'fixed',
      selectedPlan: '',
      updatePrice: false,
    }
  },
  mounted() {
    this.updatePrice = true
    if (this.showNoCost) {
      this.localNoCost = this.noCost
    }
    this.localPlans = [...this.planDiscounts.map(item => {
      item.value = this.currencyFormatter(item.value)
      return item
    })]
    if (isNumber(this.amount)) {
      this.localAmount = this.currencyFormatter(this.amount)
    } else {
      this.localAmount = this.amount
    }
    setTimeout(() => {
      this.updatePrice = false
    }, 50)
  },
  computed: {
    ...mapGetters(['tenant']),
    moneyMask() {
      return currencyConfig()
    },
    filteredPlans() {
      return this.plans.filter(item => !this.localPlans.some(plan => plan.id === item.id))
    },
    localAmountFloat() {
      return this.currencyParser(this.localAmount)
    },
  },
  watch: {
    localAmount(value) {
      this.$emit('update:amount', value)
      this.$emit('amountsUpdate', value)
    },
    localNoCost(value) {
      this.$emit('update:noCost', value)
    },
    localPlans: {
      handler: function (value) {
        this.$emit('update:planDiscounts', value)
      },
      deep: true
    }
  },
  methods: {
    selectPlan(planId) {
      if (planId && !this.localPlans.some(item => item.id === planId)) {
        const plan = this.plans.find(item => item.id === planId)
        const planRule = plan.rules.find(rule => rule.target === 'registration')
        const localAmount = this.currencyParser(this.localAmount)
        this.localPlans.push({
          id: plan.id,
          name: plan.name,
          value: this.currencyFormatter(planRule && planRule['value'] > 0 && localAmount > 0 ? localAmount - planRule['value'] : 0),
        })
      }
      setTimeout(() => {
        this.updatePrice = false
        this.selectedPlan = ''
      }, 50)
    },
    removePlan(plan) {
      this.localPlans = [...this.localPlans.filter(item => item.id !== plan.id)]
    }
  }
}
</script>

<template>
  <v-container fluid grid-list-xs class="p-0">
    <v-layout wrap>
      <v-flex xs4>
        <el-form-item :label="`${index + 1}ª Inscrição:`" class="d-block -no-validation">
          <el-input v-model.lazy="localAmount"
                    v-money="moneyMask"
                    v-if="!updatePrice"
                    :disabled="!active || localNoCost"/>
          <el-switch v-model="localNoCost"
                     class="mt-2"
                     v-if="localAmountFloat === 0 && showNoCost"
                     active-text="Sem custo">
          </el-switch>
        </el-form-item>
      </v-flex>
      <template>
        <v-flex xs4>
          <el-form-item label="Descontos" class="d-block -no-validation">
            <el-select v-model="selectedPlan"
                       filterable
                       clearable
                       :disabled="localAmountFloat === 0"
                       placeholder="Selecione um Plano ou Pacote"
                       empty-text="Nenhum registro encontrado"
                       class="el-select-full"
                       @change="selectPlan">
              <el-option
                  v-for="item in filteredPlans"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </v-flex>
        <v-flex xs4 v-if="localPlans.length > 0">
          <div class="players-class-selected-plans">
            <el-form-item :label="discountPlan.name" v-for="(discountPlan, index) in localPlans" :key="index"
                          class="d-block -no-validation mt-1 mb-0">
              <el-row :gutter="10">
                <el-col :span="21">
                  <el-input v-model.lazy="discountPlan.value"
                            v-money="moneyMask"
                            size="small"
                            v-if="!updatePrice"
                            :disabled="!active"/>
                </el-col>
                <el-col :span="3">
                  <el-button type="danger"
                             class="el-button--extra-small mt-1"
                             size="small"
                             @click="removePlan(discountPlan)">
                    <i class="fas fa-times"></i>
                  </el-button>
                </el-col>
              </el-row>
            </el-form-item>
          </div>
        </v-flex>
      </template>
    </v-layout>
  </v-container>
</template>

