<script>
import ListHeader from '../../../../components/list/Header'
import FormSaveButton from '../../../../components/form/SaveButton'
import FormCancelButton from '../../../../components/form/CancelButton'
import DropzoneUpload from '../../../../components/form/DropzoneUpload'

import DataService from '../../../../services/dataService'
import * as restfulService from '../../../../services/restfulService'
import * as notifyService from '../../../../services/notifyService'

import {currencyConfig} from '@utils/money'
import helpers from '@mixins/helpers'
import bus from '@utils/bus'
import {warningDialog} from '@utils/flash'

import {clone, cloneDeep, debounce} from 'lodash'
import {email, minValue, required, requiredIf} from 'vuelidate/lib/validators'
import {mapGetters} from 'vuex'
import moment from 'moment'

const registrationFields = [
  {
    key: 'email',
    field: 'email',
    name: 'E-mail',
    disabled: true,
    blocked: true,
    config: null
  },
  {
    key: 'phone',
    field: 'phone',
    name: 'Telefone',
    disabled: true,
    blocked: true,
    config: null
  },
  {
    key: 'cpf',
    field: 'cpf',
    name: 'CPF/DNI/CI',
    disabled: false,
    blocked: false,
    config: null
  },
  {
    key: 'city',
    field: 'city_id',
    name: 'Cidade',
    disabled: false,
    blocked: false,
    config: null
  },
  {
    key: 'sex',
    field: 'sex',
    name: 'Sexo',
    disabled: false,
    blocked: false
  },
  {
    key: 'shirt',
    field: 'shirt',
    name: 'Camiseta',
    disabled: false,
    blocked: false,
    config: null
  }
]

const defaultShirtSizes = ['P', 'M', 'G', 'GG', 'EG', 'EGG']
// const defaultShirtSizesChild = ['6', '8', '10', '12', '14'];
const shirtDefaultConfig = {
  'standard': {
    key: 'standard',
    name: 'Normal',
    sizes: [...defaultShirtSizes]
  },
  'standard_female': {
    key: 'standard_female',
    name: 'Feminina',
    sizes: [...defaultShirtSizes]
  },
  'tight': {
    key: 'tight',
    name: 'Baby Look',
    sizes: [...defaultShirtSizes]
  },
  /*
  'child': {
    key: 'child',
    name: 'Infantil',
    sizes: [...defaultShirtSizesChild]
  },
  */
}

export default {
  components: {
    ListHeader, FormSaveButton, FormCancelButton, DropzoneUpload
  },
  mixins: [helpers],
  data() {
    return {
      isEdit: false,
      isSubmitted: false,
      isLoadingData: true,
      searchCities: '',
      isSearchingCity: false,
      enableAppRegistration: false,
      disablePaymentAccountSelection: false,
      updatePrice: true,
      enableSelectBracket: false,
      selectedRegistrationFields: [...registrationFields.filter(item => item.blocked).map(item => item.key)],
      selectedImpediments: [],
      selectedShirtsConfig: {...cloneDeep(shirtDefaultConfig)},
      shirtTab: 'standard',
      form: {
        number: 1,
        name: '',
        status: 'active',
        settings: {
          max_total_pairs: 0,
          percent_points: 100,
          registration_with_payment: false,
          max_impediments_per_player: 0,
          max_registration_per_player: 1,
          contact_email: null,
          contact_phones: null,
          impediments_message: '',
          regulation_url: '',
          accept_terms: {
            enabled: false,
            title: '',
            text: ''
          },
          space_matches_between_matches: 2,
          payment_automatic_refund_enabled: false,
          generate_payment_pair_queue: false,
          registration_store_status: 'approved',
          max_pair_per_group: 3,
          payment_unique: false,
          bracket_system: 'common',
          payment_limit_minutes: 5,
          payment_limit_datetime: '',
          registration_without_partner: false,
          coupon_discount: false,
          ranking_cobrapa: false,
        },
        circuit_id: '',
        city_id: '',
        type: 'padel',
        registration_start_date: '',
        registration_end_date: '',
        app_registration_cancel_datetime: '',
        app_release_datetime: '',
        app_release_registrations_datetime: '',
        app_release_groups_datetime: '',
        app_release_matches_datetime: '',
        dates: [],
        impediments: [],
        payment_account_id: '',
        external_circuit_reference: '',
        single: false,
        cover: '',
      },
      coverFile: {
        type: '',
        size: '',
        filename: '',
        base64: '',
        remove: false,
      },
      dependencies: {
        cities: [],
        circuits: [],
        tenant_payment_accounts: [],
        registration_fields: [...registrationFields],
        stage_types: [],
        stage_impediments: [],
        sports: [],
      },
    }
  },
  validations: {
    form: {
      settings: {
        percent_points: {required, minValue: minValue(0)},
        max_impediments_per_player: {required},
        max_registration_per_player: {required},
        contact_email: {email},
        contact_phones: {required},
      },
      name: {required},
      city_id: {required},
      registration_start_date: {
        requiredIf: requiredIf(function () {
          return this.enableAppRegistration
        })
      },
      registration_end_date: {
        requiredIf: requiredIf(function () {
          return this.enableAppRegistration
        })
      },
      dates: {required}
    }
  },
  computed: {
    ...mapGetters(['tenant', 'hasRankingCobrapaEnabled']),
    moneyMask() {
      return currencyConfig()
    },
    paymentAccount() {
      return (this.tenant?.has_payment_account ?? false)
    },
    coverPath() {
      if (!this.form.cover) {
        return ''
      }
      return this.form.cover
    },
    localShirtDefaultConfig() {
      return shirtDefaultConfig
    },
    hasShirtFieldSelected() {
      return this.selectedRegistrationFields.includes('shirt')
    },
    filteredStageImpediments() {
      const weekdays = Array.isArray(this.form.dates) && this.form.dates.length
          ? this.form.dates.map(date => moment(date).weekday())
          : []
      return this.dependencies.stage_impediments.filter(item => weekdays.includes(item.weekday))
    },
    formRegistrationStoreStatusAlertType() {
      if (this.form.settings.registration_store_status === 'created') {
        return 'warning'
      } else if (this.form.settings.registration_store_status === 'restricted') {
        return 'error'
      } else if (this.form.settings.registration_store_status === 'approved') {
        return 'success'
      }
      return 'default'
    },
    formRegistrationStoreStatusLabel() {
      if (this.form.settings.registration_store_status === 'created') {
        return 'Pendente'
      } else if (this.form.settings.registration_store_status === 'restricted') {
        return 'Restrita'
      } else if (this.form.settings.registration_store_status === 'approved') {
        return 'Aprovada'
      }
      return ''
    }
  },
  watch: {
    enableAppRegistration(value, oldValue) {
      if (oldValue && !value) {
        this.form.registration_start_date = ''
        this.form.registration_end_date = ''
      }
    },
    'form.single'(value) {
      if (value) {
        this.form.settings.registration_without_partner = false
      }
    },
    'form.settings.registration_with_payment'(value) {
      if (value) {
        if (!this.isEdit || this.form.settings.registration_store_status === 'approved') {
          this.form.settings.registration_store_status = 'created'
        }
        if (!this.selectedRegistrationFields.some(item => item.key === 'cpf')) {
          let cpfField = registrationFields.find(item => item.key === 'cpf')
          cpfField.disabled = true
          this.selectedRegistrationFields.push('cpf')
        }
        if (this.dependencies.tenant_payment_accounts.length === 1) {
          this.form.payment_account_id = this.dependencies.tenant_payment_accounts[0].id
        }
      } else {
        if (!this.isEdit || this.form.settings.registration_store_status === 'restricted') {
          this.form.settings.registration_store_status = 'approved'
        }
        let cpfField = registrationFields.find(item => item.key === 'cpf')
        cpfField.disabled = false
        this.selectedRegistrationFields = this.selectedRegistrationFields.filter(item => item !== 'cpf')
      }
    },
    searchCities: debounce(function (value) {
      value && value !== this.form.city_id && this.onSearchCity(value)
    }, 300),
    'form.settings.payment_limit_minutes': function (value) {
      if (value < 1) {
        this.form.settings.payment_limit_minutes = 1
      }
    },
    'form.settings.registration_store_status': function (value) {
      if (value === 'restricted') {
        this.form.settings.payment_unique = true
        this.form.settings.payment_limit_datetime = ''
      }
    },
    'form.settings.payment_unique': function (value) {
      if (!value) {
        this.form.settings.coupon_discount = false
      }
    }
  },
  async mounted() {
    await this.getDependencies()
    if (this.dependencies.tenant_payment_accounts.length <= 1) {
      this.disablePaymentAccountSelection = true
    }
    const id = this.$route.params.id || null
    if (id) {
      this.getStage(id)
    } else {
      const paymentAccount = this.dependencies.tenant_payment_accounts.find(item => item.main)
      if (paymentAccount) {
        this.form.payment_account_id = paymentAccount.id
      }
      this.isLoadingData = false
      this.updatePrice = false
      bus.$emit('hide-loader')
    }
  },
  methods: {
    getStage(id) {
      this.isEdit = true
      restfulService.get('stage', 'show', id)
          .then(response => {
            response.second_registration_discount = this.currencyFormatter(response.second_registration_discount)
            if (response.dates.length) {
              response.dates = [...response.dates.map(item => item.date)]
            }
            if (response.settings?.accept_terms === null) {
              response.settings.accept_terms = {...this.form.settings.accept_terms}
            }
            if (response.settings?.bracket_system === 'cobrapa') {
              this.enableSelectBracket = true
            }
            if (response.settings?.registration_required_fields !== null) {
              this.selectedRegistrationFields = [...new Set([...this.selectedRegistrationFields, ...this.dependencies.registration_fields.filter(item => response.settings?.registration_required_fields.some(field => field.key === item.key)).map(item => item.key)])]
            }
            if (response.impediments.length) {
              this.selectedImpediments = [...response.impediments.map(item => item.impediment_id)]
            }
            this.form = {...this.form, ...response}
            if (this.selectedRegistrationFields.includes('shirt')) {
              const shirtData = response.settings?.registration_required_fields.find(item => item.key === 'shirt')
              if (shirtData && shirtData.config) {
                this.selectedShirtsConfig = {...cloneDeep(shirtData.config)}
              }
            }
            if (this.form.city) {
              this.dependencies.cities.push(this.form.city)
            }
            if (this.form.registration_start_date && this.form.registration_end_date) {
              this.enableAppRegistration = true
            }
            this.isLoadingData = false
            this.updatePrice = false
            bus.$emit('hide-loader')
          })
          .catch(e => {
            console.log(e)
            this.isLoadingData = false
            this.updatePrice = false
            bus.$emit('hide-loader')
          })
    },
    getDependencies() {
      return new Promise(resolve => {
        DataService.get([
          {domain: 'circuit'},
          {domain: 'stage_type'},
          {domain: 'sport'},
          {domain: 'tenant_payment_account', data: {status: 'active'}},
          {domain: 'stage_impediment', data: {orderBy: [{column: 'order'}]}}]).then((result) => {
          this.dependencies = {...this.dependencies, ...result}
          resolve()
        })
      })
    },
    onSearchCity(query) {
      return new Promise((resolve, reject) => {
        this.dependencies.cities.splice(0)
        if (query !== '') {
          this.isSearchingCity = true
          let data = {query}
          restfulService.post('city', 'autocomplete', null, data)
              .then((response) => {
                this.dependencies.cities.splice(0)
                this.dependencies.cities = [...this.dependencies.cities, ...response]
                this.isSearchingCity = false
                resolve()
              })
              .catch(e => {
                console.log(e)
                reject()
              })
        }
      })
    },
    selectFile(event) {
      const file = event.target.files[0]
      if (!['image/jpg', 'image/jpeg', 'image/png', 'image/webp'].includes(file.type)) {
        warningDialog({
          title: 'Formato de arquivo inválido',
          text: 'Selecione um arquivo com extensão .jpeg, .png, .webp',
        })
        event.target.value = null
        return
      }
      this.coverFile.filename = file.name
      this.coverFile.size = file.size
      this.coverFile.type = file.type
      let reader = new window.FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        this.coverFile.base64 = reader.result
      }
    },
    removeCover() {
      this.form.cover = ''
      this.coverFile.remove = true
      this.coverFile.base64 = ''
    },
    onSend() {
      const {invalid} = this.validateFormFocus(this.$v.form, 'form', this.$refs)
      if (!invalid) {
        bus.$emit('show-loader')
        this.isSubmitted = true
        const data = clone(this.form)
        data.second_registration_discount = this.currencyParser(data.second_registration_discount)
        data.settings.max_pair_per_group = data.settings.max_pair_per_group.toString()
        data.settings.registration_required_fields = this.dependencies.registration_fields.filter(item => this.selectedRegistrationFields.some(key => key === item.key))
        const shirtFieldIndex = data.settings.registration_required_fields.findIndex(item => item.key === 'shirt')
        if (shirtFieldIndex > -1) {
          data.settings.registration_required_fields[shirtFieldIndex].config = this.selectedShirtsConfig
        }
        delete data.cover
        if (this.coverFile.base64 || this.coverFile.remove === true) {
          data.cover_file = {...this.coverFile}
        }
        data.impediments = [...this.selectedImpediments]
        restfulService.save('stage', null, null, data)
            .then(response => {
              notifyService.success({hideLoader: true})
              this.isSubmitted = false
              this.$router.push({name: 'stage.show', params: {id: response.id}})
              this.getStage(response.id)
            })
            .catch(e => {
              this.isSubmitted = false
              bus.$emit('hide-loader')
              console.log(e)
            })
      }
    }
  }
}
</script>

<template>
  <div>

    <ListHeader title="Torneios"/>

    <el-form ref="form"
             :model="form"
             label-position="top"
             @submit.prevent="onSend">

      <el-row :gutter="20">
        <el-col :span="12">

          <el-card class="el-card__save_form box-card">
            <div slot="header">
              <span>Dados do Torneio</span>
            </div>

            <el-row :gutter="20">
              <el-col :span="14">
                <el-form-item label="Nome"
                              class="is-required"
                              :class="{ 'el-form-item--error': $v.form.name.$error }">
                  <el-input v-model="form.name"
                            ref="form.name"
                            @input="$v.form.name.$touch()"
                            :maxlength="100"/>
                  <div class="el-form-item__error" v-if="$v.form.name.$error">
                    Esse campo é obrigatório
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item label="Cidade"
                              class="is-required"
                              :class="{ 'el-form-item--error': $v.form.city_id.$error }">
                  <v-autocomplete v-model="form.city_id"
                                  :items="dependencies.cities"
                                  :loading="isSearchingCity"
                                  :search-input.sync="searchCities"
                                  ref="form.city_id"
                                  autocomplete="off"
                                  @input="$v.form.city_id.$touch()"
                                  @blur="$v.form.city_id.$touch()"
                                  no-data-text="Nenhum registro encontrado"
                                  color="blue-grey lighten-2"
                                  label="Selecione uma cidade"
                                  item-text="name_with_state"
                                  item-value="id"
                                  single-line
                                  no-filter
                                  clearable
                                  outline
                                  hide-details
                                  hide-selected
                                  class="v-autocomplete-custom">
                    <template v-slot:item="data">
                      <v-list-tile-content>
                        <v-list-tile-title>
                          {{ data.item.name_with_state }}
                        </v-list-tile-title>
                      </v-list-tile-content>
                    </template>
                    <template v-slot:selection="data">
                      <v-list-tile-content>
                        <v-list-tile-title>
                          {{ data.item.name_with_state }}
                        </v-list-tile-title>
                      </v-list-tile-content>
                    </template>
                  </v-autocomplete>
                  <div class="el-form-item__error" v-if="$v.form.city_id.$error">
                    Esse campo é obrigatório
                  </div>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="Telefones de Contato"
                              class="is-required"
                              :class="{ 'el-form-item--error': $v.form.settings.contact_phones.$error }">
                  <el-input v-model="form.settings.contact_phones"
                            ref="form.settings.contact_phones"
                            :maxlength="255"/>
                  <div class="el-form-item-info">Ex.: João (99 99999.9999)</div>
                  <div class="el-form-item__error" v-if="$v.form.settings.contact_phones.$error">
                    <span v-if="!$v.form.settings.contact_phones.required">Esse campo é obrigatório</span>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Email de Contato"
                              :class="{ 'el-form-item--error': $v.form.settings.contact_email.$error }">
                  <el-input v-model="form.settings.contact_email"
                            @input="$v.form.settings.contact_email.$touch()"
                            :maxlength="255"/>
                  <div class="el-form-item__error" v-if="$v.form.settings.contact_email.$error">
                    Esse email é inválido
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="Status">
                  <el-select v-model="form.status"
                             placeholder="Status"
                             class="el-select-full"
                             value="">
                    <el-option key="active" label="ATIVA" value="active"></el-option>
                    <el-option key="inactive" label="INATIVA" value="inactive"></el-option>
                    <el-option key="finished" label="FINALIZADA" value="finished"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Link do Regulamento">
                  <el-input v-model="form.settings.regulation_url"/>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="Formato"
                              class="is-required"
                              :class="{ 'el-form-item--error': $v.form.name.$error }">
                  <el-select v-model="form.single"
                             placeholder="Formato"
                             class="el-select-full">
                    <el-option label="Duplas" :value="false"></el-option>
                    <el-option label="Simples" :value="true"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Tipo de Torneio">
                  <el-select v-model="form.type"
                             placeholder="Selecione um tipo"
                             empty-text="Nenhum registro encontrado"
                             class="el-select-full">
                    <el-option
                        v-for="item in dependencies.sports"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="8">
                <el-form-item label="Circuito Local">
                  <el-select v-model="form.circuit_id"
                             :disabled="!!form.external_circuit_reference"
                             filterable
                             clearable
                             placeholder="Selecione um circuito"
                             empty-text="Nenhum registro encontrado"
                             class="el-select-full">
                    <el-option
                        v-for="item in dependencies.circuits"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="Código de Importação de Circuito Externo">
                  <el-input v-model="form.external_circuit_reference" :disabled="!!form.circuit_id"/>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="% da Pontuação do Circuito"
                              class="is-required"
                              :class="{ 'el-form-item--error': $v.form.settings.percent_points.$error }">
                  <el-input type="number"
                            :min="0"
                            :step="1"
                            :max="1000"
                            v-model="form.settings.percent_points"
                            @input="$v.form.settings.percent_points.$touch()"/>
                  <div class="el-form-item-info">100 = Valor padrão</div>
                  <div class="el-form-item__error" v-if="$v.form.settings.percent_points.$error">
                    <span v-if="!$v.form.settings.percent_points.required">Esse campo é obrigatório</span>
                    <span v-if="!$v.form.settings.percent_points.minValue">O Porcentagem da Pontuação deve ser maior que 0</span>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
            <el-form-item label="Ranking COBRAPA" v-if="hasRankingCobrapaEnabled">
              <el-switch v-model="form.settings.ranking_cobrapa"
                         active-text="Sim"
                         :active-value="true"
                         inactive-text="Não"
                         :inactive-value="false"
                         active-color="#0B970D">
              </el-switch>
            </el-form-item>
          </el-card>
          <el-card class="el-card__save_form box-card">
            <div slot="header">
              <span>Datas e Publicação no App</span>
            </div>
            <el-form-item label="Datas do Torneio"
                          class="is-required"
                          :class="{ 'el-form-item--error': $v.form.dates.$error }">
              <el-date-picker
                  v-model="form.dates"
                  ref="form.dates"
                  @input="$v.form.dates.$touch()"
                  type="dates"
                  format="dd/MM/yyyy"
                  value-format="yyyy-MM-dd">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="Banner do Torneio">
              <input v-if="!coverFile.reset"
                     type="file"
                     @change="selectFile($event)">
              <div v-if="coverPath" class="stage-cover">
                <div class="remove" @click="removeCover" title="Remover Banner">
                  <i class="fas fa-times"></i>
                </div>
                <img :src="coverPath" alt="Banner do Torneio">
              </div>
            </el-form-item>
            <v-divider/>
            <h4 class="mt-3">Publicação do Torneio no App</h4>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="Data de Publicação do Torneio">
                  <el-date-picker v-model="form.app_release_datetime"
                                  placeholder="Publicação do Torneio"
                                  type="datetime"
                                  format="dd/MM/yyyy HH:mm"
                                  value-format="yyyy-MM-dd HH:mm:ss">
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Data de Publicação dos Inscritos">
                  <el-date-picker v-model="form.app_release_registrations_datetime"
                                  placeholder="Publicação dos Inscritos"
                                  type="datetime"
                                  format="dd/MM/yyyy HH:mm"
                                  value-format="yyyy-MM-dd HH:mm:ss">
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="Data de Publicação dos Grupos">
                  <el-date-picker v-model="form.app_release_groups_datetime"
                                  placeholder="Publicação dos Grupos"
                                  type="datetime"
                                  format="dd/MM/yyyy HH:mm"
                                  value-format="yyyy-MM-dd HH:mm:ss">
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Data de Publicação dos Jogos">
                  <el-date-picker v-model="form.app_release_matches_datetime"
                                  placeholder="Publicação dos Jogos"
                                  type="datetime"
                                  format="dd/MM/yyyy HH:mm"
                                  value-format="yyyy-MM-dd HH:mm:ss">
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
          </el-card>

          <el-alert class="mt-3 mb-2" :closable="false" show-icon type="warning" v-if="!isEdit">
            <span class="fs-14">
              A inserção de <strong>patrocinadores/parceiros</strong> será habilitada após a criação do torneio
            </span>
          </el-alert>
          <div :class="{'disabled': !isEdit}">
            <dropzone-upload :owner-id="form.id"
                             :list="form.images"
                             title="Patrocinadores/Parceiros"
                             btn-action="Inserir Logo"/>
          </div>
        </el-col>
        <el-col :span="12">
          <el-card class="el-card__save_form box-card">
            <div slot="header">
              <span>Inscrições pelo App</span>
              <el-switch v-model="enableAppRegistration"
                         active-text="Habilitar"
                         :active-value="true"
                         inactive-text=""
                         :inactive-value="false">
              </el-switch>
            </div>
            <template v-if="enableAppRegistration">
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="Data de Início das Inscrições"
                                :class="{ 'el-form-item--error': $v.form.registration_start_date.$error, 'is-required': enableAppRegistration}">
                    <el-date-picker v-model="form.registration_start_date"
                                    ref="form.registration_start_date"
                                    @input="$v.form.registration_start_date.$touch()"
                                    placeholder="Data de Inicio"
                                    type="datetime"
                                    format="dd/MM/yyyy HH:mm"
                                    value-format="yyyy-MM-dd HH:mm:ss">
                    </el-date-picker>
                    <div class="el-form-item__error" v-if="$v.form.registration_start_date.$error">
                      Esse campo é obrigatório
                    </div>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="Data Final das Inscrições"
                                :class="{ 'el-form-item--error': $v.form.registration_end_date.$error, 'is-required': enableAppRegistration }">
                    <el-date-picker v-model="form.registration_end_date"
                                    ref="form.registration_end_date"
                                    @input="$v.form.registration_end_date.$touch()"
                                    placeholder="Data Final"
                                    type="datetime"
                                    format="dd/MM/yyyy HH:mm"
                                    value-format="yyyy-MM-dd HH:mm:ss">
                    </el-date-picker>
                    <div class="el-form-item__error" v-if="$v.form.registration_end_date.$error">
                      Esse campo é obrigatório
                    </div>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="Data Limite para Cancelamento">
                    <el-date-picker v-model="form.app_registration_cancel_datetime"
                                    placeholder="Data Limite"
                                    type="datetime"
                                    format="dd/MM/yyyy HH:mm"
                                    value-format="yyyy-MM-dd HH:mm:ss">
                    </el-date-picker>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-alert type="warning"
                        class="pt-3 pb-3 mb-3"
                        show-icon
                        :closable="false"
                        v-if="!paymentAccount">
                <div slot="title" class="fs-16">
                  Para habilitar o pagamento online cadastre uma conta de pagamentos
                </div>
                <div class="fs-14">
                  Acesse o Menu: Sistema -> Meu Clube -> Configurações
                  <div>
                    ou
                    <router-link
                        class="el-tag el-tag--mini el-tag--primary"
                        :to="{name: 'tenant_settings.show', params: {tab: 'config'}}">
                      Clique aqui
                    </router-link>
                  </div>
                </div>
              </el-alert>
              <div v-else class="highlight-card -payment mb-3">
                <el-row :gutter="10">
                  <el-col :span="12">
                    <el-form-item label="Habilitar o Pagamento Online">
                      <el-switch v-model="form.settings.registration_with_payment"
                                 active-text="Sim"
                                 :active-value="true"
                                 inactive-text="Não"
                                 :inactive-value="false"
                                 active-color="#0B970D">
                      </el-switch>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12" v-if="form.settings.registration_with_payment && !form.single">
                    <el-form-item label="Pagamento Único da Dupla">
                      <el-switch v-model="form.settings.payment_unique"
                                 :disabled="form.settings.registration_store_status === 'restricted'"
                                 active-text="Sim"
                                 :active-value="true"
                                 inactive-text="Não"
                                 :inactive-value="false"
                                 active-color="#0B970D">
                      </el-switch>
                    </el-form-item>
                  </el-col>
                </el-row>
                <template v-if="form.settings.registration_with_payment">
                  <el-row>
                    <el-col :span="12" v-if="!form.single">
                      <el-form-item label="Estorno Automático ao Cancelar Inscrição">
                        <el-switch v-model="form.settings.payment_automatic_refund_enabled"
                                   active-text="Sim"
                                   :active-value="true"
                                   inactive-text="Não"
                                   :inactive-value="false"
                                   active-color="#0B970D">
                        </el-switch>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="Gerar Pagamento p/ Fila de Espera">
                        <el-switch v-model="form.settings.generate_payment_pair_queue"
                                   active-text="Sim"
                                   :active-value="true"
                                   inactive-text="Não"
                                   :inactive-value="false"
                                   active-color="#0B970D">
                        </el-switch>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="10">
                    <el-col :span="12" v-if="form.settings.registration_store_status !== 'restricted'">
                      <el-form-item label="Data Limite para os Pagamentos">
                        <el-date-picker v-model="form.settings.payment_limit_datetime"
                                        placeholder="Data Final"
                                        type="datetime"
                                        format="dd/MM/yyyy HH:mm"
                                        value-format="yyyy-MM-dd HH:mm:ss">
                        </el-date-picker>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12" v-else>
                      <el-form-item label="Tempo Limite para o Pagamento (Minutos)" class="mb-0">
                        <el-input type="number"
                                  :min="1"
                                  :step="1"
                                  v-model="form.settings.payment_limit_minutes"/>
                      </el-form-item>
                      <div class="el-form-item-info">O valor mínimo é de 1 minuto - Após esse tempo a inscrição será
                        cancelada automaticamente
                      </div>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="Conta de Pagamentos">
                        <el-select v-model="form.payment_account_id"
                                   clearable
                                   :disabled="disablePaymentAccountSelection"
                                   placeholder="Selecione uma Conta de Pagamento"
                                   empty-text="Nenhum registro encontrado"
                                   class="el-select-full">
                          <el-option
                              v-for="item in dependencies.tenant_payment_accounts"
                              :key="item.id"
                              :label="`${item.bank} - ${item.agency}/${item.account}-${item.digit}`"
                              :value="item.id">
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </template>
              </div>
              <el-row :gutter="20">
                <el-col :span="10">
                  <el-form-item label="Status Inicial da Inscrição">
                    <el-button-group>
                      <el-button size="small"
                                 :type="form.settings.registration_store_status === 'created' ? 'warning': 'default'"
                                 @click="form.settings.registration_store_status = 'created'">
                        Pendente
                      </el-button>
                      <el-button size="small"
                                 :type="form.settings.registration_store_status === 'restricted' ? 'danger': 'default'"
                                 @click="form.settings.registration_store_status = 'restricted'"
                                 v-if="form.settings.registration_with_payment">
                        Restrita
                      </el-button>
                      <el-button size="small"
                                 :type="form.settings.registration_store_status === 'approved' ? 'success': 'default'"
                                 @click="form.settings.registration_store_status = 'approved'"
                                 v-else
                                 :disabled="form.settings.registration_with_payment">
                        Aprovada
                      </el-button>
                    </el-button-group>
                  </el-form-item>
                </el-col>
                <el-col :span="14">
                  <el-alert :type="formRegistrationStoreStatusAlertType"
                            class="pt-3 pb-3 mb-3"
                            show-icon
                            :closable="false">
                    <div slot="title" class="fs-16">
                      Status: <strong>{{ formRegistrationStoreStatusLabel }}</strong>
                    </div>
                    <div class="fs-14">
                      <template v-if="form.settings.registration_store_status === 'created'">
                        Quando o atleta realizar a inscrição pelo app, a inscrição ficará como pendente até que o
                        pagamento seja aprovado
                      </template>
                      <template v-else-if="form.settings.registration_store_status === 'restricted'">
                        <div>
                          Quando o atleta estiver realizando a inscrição pelo app, a inscrição será cancelada se caso o
                          pagamento não for realizado em até <strong>{{ form.settings.payment_limit_minutes }}
                          minuto(s)</strong>
                        </div>
                        <div class="mt-2" v-if="!form.single">
                          <strong>Nesse modo restrito indicamos que o pagamento único da dupla seja ativado para
                            facilitar o
                            processo de pagamento</strong>
                        </div>
                      </template>
                      <template v-else-if="form.settings.registration_store_status === 'approved'">
                        Quando o atleta realizar a inscrição pelo app, a inscrição entrará como aprovada
                      </template>
                    </div>
                  </el-alert>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="Nº Máximo de Duplas Inscritas"
                                class="is-required">
                    <el-input type="number" :min="0" :step="1" v-model="form.settings.max_total_pairs"/>
                    <div class="el-form-item-info">0 = Sem limite</div>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="Nº Máximo de Inscrições por Atleta"
                                class="is-required"
                                :class="{ 'el-form-item--error': $v.form.settings.max_registration_per_player.$error }">
                    <el-input type="number" :min="1" :step="1" :max="4"
                              v-model="form.settings.max_registration_per_player"
                              @input="$v.form.settings.max_registration_per_player.$touch()"/>
                    <div class="el-form-item-info">Defina em quantas categorias os atletas podem se inscrever</div>
                    <div class="el-form-item__error"
                         v-if="$v.form.settings.max_registration_per_player.$error">
                      <span
                          v-if="!$v.form.settings.max_registration_per_player.required">Esse campo é obrigatório</span>
                    </div>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="Habilitar cupom de desconto">
                    <el-switch v-model="form.settings.coupon_discount"
                               active-text="Sim"
                               :disabled="!form.settings.payment_unique"
                               :active-value="true"
                               inactive-text="Não"
                               :inactive-value="false"
                               class="mt-2">
                    </el-switch>
                    <div class="el-form-item-info" v-if="!form.settings.payment_unique">
                      Só é permitido habilitar o uso de cupons quando o pagamento único está ativado
                    </div>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item v-if="!form.single" label="Permitir inscrição sem parceiro?">
                    <el-switch v-model="form.settings.registration_without_partner"
                               active-text="Sim"
                               :active-value="true"
                               inactive-text="Não"
                               :inactive-value="false"
                               class="mt-2">
                    </el-switch>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="Nº Máximo de Impedimentos por Atleta"
                                class="is-required"
                                :class="{ 'el-form-item--error': $v.form.settings.max_impediments_per_player.$error }">
                    <el-input type="number" :min="0" :step="1" :max="4"
                              v-model="form.settings.max_impediments_per_player"
                              @input="$v.form.settings.max_impediments_per_player.$touch()"/>
                    <div class="el-form-item__error"
                         v-if="$v.form.settings.max_impediments_per_player.$error">
                      <span v-if="!$v.form.settings.max_impediments_per_player.required">Esse campo é obrigatório</span>
                    </div>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-form-item label="Impedimentos" v-if="this.form.settings.max_impediments_per_player > 0">
                <el-transfer v-model="selectedImpediments"
                             :data="filteredStageImpediments"
                             :titles="['Disponíveis', 'Selecionados']"
                             class="el-transfer-auto-height"
                             :props="{
                                key: 'id',
                                label: 'name',
                                disabled: 'disabled'
                              }">
                </el-transfer>
              </el-form-item>
              <el-form-item label="Campos Obrigatórios na Inscrição">
                <el-transfer v-model="selectedRegistrationFields"
                             :data="this.dependencies.registration_fields"
                             :titles="['Campos', 'Obrigatórios']"
                             class="el-transfer-auto-height"
                             :props="{
                                key: 'key',
                                label: 'name',
                                disabled: 'disabled'
                              }">
                </el-transfer>
              </el-form-item>
              <template v-if="hasShirtFieldSelected">
                <el-form-item label="Tipos e Tamanhos das Camisetas" class="mb-4">
                  <el-tabs v-model="shirtTab">
                    <el-tab-pane v-for="type in localShirtDefaultConfig"
                                 :label="type.name"
                                 :key="type.key"
                                 :name="type.key">
                      <el-checkbox-group v-model="selectedShirtsConfig[type.key].sizes">
                        <el-checkbox v-for="size in localShirtDefaultConfig[type.key].sizes"
                                     :key="size"
                                     :label="size"/>
                      </el-checkbox-group>
                    </el-tab-pane>
                  </el-tabs>
                </el-form-item>
              </template>
              <el-form-item label="Mensagem/Aviso para os Atletas">
                <el-input v-model="form.settings.impediments_message"
                          type="textarea"
                          :rows="4"
                          :maxlength="500"/>
              </el-form-item>
              <h3 class="mt-3">Termos de Aceite</h3>
              <el-form-item label="Habilitar?">
                <el-switch v-model="form.settings.accept_terms.enabled"
                           active-text="Sim"
                           :active-value="true"
                           inactive-text="Não"
                           :inactive-value="false">
                </el-switch>
              </el-form-item>
              <el-form-item label="Texto">
                <el-input v-model="form.settings.accept_terms.title" :disabled="!form.settings.accept_terms.enabled"/>
              </el-form-item>
              <el-form-item label="Texto" v-if="false">
                <el-input v-model="form.settings.accept_terms.text"
                          type="textarea"
                          :rows="4"
                          :maxlength="500"/>
              </el-form-item>
            </template>

          </el-card>

        </el-col>
      </el-row>
      <el-card class="el-card__btn_form">
        <el-form-item>
          <form-save-button :event-click="onSend"
                            :is-disabled="isSubmitted"
                            :is-submitted="isSubmitted">
          </form-save-button>
          <form-cancel-button route-name="stage.index"/>
        </el-form-item>
      </el-card>

    </el-form>

  </div>
</template>
