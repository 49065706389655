<script>
import RegistrationStatus from '../registration/components/Status.vue'
import RegistrationPayment from '../registration/components/Payment.vue'

export default {
  components: {RegistrationStatus, RegistrationPayment},
  name: 'pair-names',
  props: {
    pair: Object,
    player: Object,
    playerId: {
      type: Number,
      default: 0
    },
    paymentEnabled: {
      type: Boolean,
      default: false
    },
    paymentUnique: {
      type: Boolean,
      default: false
    },
    playerNumber: {
      type: Number,
      default: 1
    },
  },
  data() {
    return {
      showData: false
    }
  },
  computed: {
    hasTwoPlayersClass() {
      return (this.playerNumber === 1 && this.pair.player1_two_players_class) || (this.playerNumber === 2 && this.pair.player2_two_players_class)
    },
    playerName() {
      return this.player?.data?.name || this.player.player.name
    },
    registrationStatus() {
      if (this.player.status === 'created') {
        return 'Pendente'
      }
      return 'Aprovada'
    },
    registrationStatusColor() {
      if (this.player.status === 'created') {
        return 'warning'
      }
      return 'success'
    },
    transactionStatus() {
      if (this.player.transaction && this.player.transaction.status === 'created') {
        return 'Pendente'
      }
      return 'Aprovado'
    },
    transactionStatusColor() {
      if (this.player.transaction && this.player.transaction.status === 'created') {
        return 'warning'
      }
      return 'success'
    },
    clientName() {
      return this.player?.player.name || ''
    },
    clientCpf() {
      return this.player?.data?.cpf || this.player?.player.cpf || ''
    },
    clientEmail() {
      return this.player?.data?.email || this.player?.player.email || ''
    },
    clientPhone() {
      return this.player?.data?.phone || this.player?.player.phone || ''
    },
    playersClass() {
      let playersClass = [...this.pair.player1_players_class]
      if (this.playerNumber === 2) {
        playersClass = [...this.pair.player2_players_class]
      }
      return playersClass.map(item => item.name)
    },
    amount() {
      if (this.player.transaction) {
        return this.player.transaction.amount_charged ?? ((this.player.transaction?.amount || 0) - (this.player.transaction?.discount || 0))
      }
      return this.player.amount
    }
  },
  methods: {
    copyToClipboard(transaction) {
      navigator.clipboard.writeText(transaction.hash)
      this.$notify({
        title: 'Link de pagamento copiado!',
        type: 'success',
        duration: 1000,
        position: 'bottom-right'
      })
    },
  }
}
</script>

<template>
  <div>
    <el-popover
        :placement="playerNumber === 1 ? 'right' : 'left'"
        width="300"
        :value="showData"
        :disabled="playerId === 0 || !playerId"
        trigger="hover">
      <span slot="reference"
            @click="showData = !showData"
            :class="['mb-1', {'blue-grey--text lighten-1': hasTwoPlayersClass}]"
            :title="hasTwoPlayersClass ? 'Atletas em duas categorias': ''">
        {{ playerName }}
        <span v-if="hasTwoPlayersClass"
              class="ml-1">
          <i class="fas fa-users"></i>
        </span>
      </span>
      <div>
        <div class="mb-2">
          <RouterLink :to="{name: 'client.update', params: {id: playerId}}"
                      class="base-link"
                      title="Editar Cadastro do Cliente"
                      target="_blank">
            {{ playerName }} (Id: {{ playerId }})
          </RouterLink>
        </div>
        <div v-if="playersClass.length > 1">Categorias: <strong>{{ playersClass.join(', ') }}</strong></div>
        <div v-if="clientCpf">CPF: <strong>{{ clientCpf }}</strong></div>
        <div v-if="clientEmail">E-mail: <strong>{{ clientEmail }}</strong></div>
        <div v-if="clientPhone">Telefone: <strong>{{ clientPhone }}</strong></div>
        <div v-if="!paymentUnique || (paymentUnique && playerNumber === 1)">
          Valor: <strong>{{ amount | currencyFormatter }}</strong>
        </div>
        <div class="mt-1 mb-2" v-if="player.status === 'approved'">
          <el-tag class="el-tag--status" type="success">
            Inscrição Aprovada
          </el-tag>
        </div>
        <RouterLink :to="{name: 'registration.update', params: {id: this.player.id}}"
                    class="base-link"
                    title="Editar Dados da Inscrição"
                    target="_blank">
          Editar Dados da Inscrição
        </RouterLink>
        <div :class="['mt-2 flex-c -jc-ce gap-25']" v-if="pair.status !== 'approved'">
          <div class="text-center">
            <div><strong>Status</strong></div>
            <registration-status inline
                                 :registration="player"
                                 :player-number="playerNumber"
                                 :status="player.status"
                                 @updateRegistration="$emit('updateRegistration')"/>
          </div>
          <div class="text-center" v-if="!paymentUnique || (paymentUnique && playerNumber === 1)">
            <div>
              <strong>Pagamento</strong>
            </div>
            <el-button type="success"
                       size="mini"
                       class="el-button--extra-small"
                       v-if="player?.transaction?.status === 'created'"
                       @click="copyToClipboard(player?.transaction)"
                       title="Copiar Link de Pagamento">
              <i class="fas fa-money-bill"></i>
              <span class="ml-2">Copiar Link de Pgto.</span>
            </el-button>
            <registration-payment inline
                                  :registration="player"
                                  :transaction="player.transaction"
                                  :payment-enabled="paymentEnabled"
                                  :payment-unique="paymentUnique"
                                  :player-number="playerNumber"
                                  @updateRegistration="$emit('updateRegistration')"/>
          </div>
        </div>
      </div>
    </el-popover>
    <div class="mt-1" v-if="player?.player?.id !== 0 && pair.status !== 'approved'">
      <el-tag size="mini" :type="registrationStatusColor" class="font-weight-bold">
        Inscrição {{ registrationStatus }}
      </el-tag>
      <el-tag size="mini" :type="transactionStatusColor" class="font-weight-bold ml-2" v-if="this.player.transaction">
        Pagamento {{ transactionStatus }}
      </el-tag>
    </div>
  </div>
</template>
