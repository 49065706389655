<script>
import OnlinePayment from "./online_payment/Index.vue";
import StonePayment from "./stone_payment/Index.vue";
import Payment from "./payment/Index.vue";
import Nf from "./nf/Index.vue";
import Totem from "./totem/Index.vue";
import FaceRecon from "./face_recon/Index.vue";
import RankingCobrapa from "./ranking_cobrapa/Index.vue";

import {mapGetters} from "vuex";
import {SETTINGS_TYPES} from "../../service";

export default {
  components: {OnlinePayment, StonePayment, Payment, Nf, Totem, FaceRecon, RankingCobrapa},
  name: 'tenant-config',
  data() {
    return {
      isLoading: {}
    }
  },
  computed: {
    ...mapGetters(['hasFaceReconModuleEnable']),
    types() {
      return SETTINGS_TYPES
    }
  },
  methods: {
    isLoadingForce(type) {
      return this.isLoading[type] === true
    }
  }
}
</script>

<template>
  <el-form label-position="top">
    <v-container grid-list-lg class="m-0 p-0">
      <v-layout row wrap>
        <v-flex xs12 sm6>
          <online-payment/>
          <stone-payment class="mt-3" v-if="!isLoadingForce(types.tenant_stone_payment)"/>
          <payment class="mt-3" v-if="!isLoadingForce(types.tenant_payment)"/>
        </v-flex>
        <v-flex xs12 sm6>
          <nf v-if="!isLoadingForce(types.tenant_nf)"/>
          <face-recon class="mt-3" v-if="hasFaceReconModuleEnable || !isLoadingForce(types.face_recon)"/>
          <totem class="mt-3" v-if="!isLoadingForce(types.totem)"/>
          <ranking-cobrapa class="mt-3"/>
        </v-flex>
      </v-layout>
    </v-container>
  </el-form>
</template>
